import { FormattedMessage } from "react-intl";
import { Divider, pxToRem } from "@getbread/dough";
import { styled } from "@mui/material/styles";
import { loanRoutes } from "../../pages/LoansPage/loanRoutes";
import { getConfig } from "../../utils/getConfig";
import { useConfirmLanguageChange } from "../ConfirmLanguageChange";
import { LocaleDropdown } from "../LocaleDropdown/LocaleDropdown";
import { useLocale } from "../LocaleProvider";
import { HeaderExteriorLink, HeaderLink } from "./HeaderLink";

// Last nav link had too much space from the right edge of the screen
const DesktopLink = styled(HeaderLink)`
	margin: 0 var(--space-s);
	font-size: ${pxToRem(16)};

	&:last-child {
		margin-right: 0;
	}
`;

const DesktopLinkExternal = styled(HeaderExteriorLink)`
	margin: 0 var(--space-s);
	font-size: ${pxToRem(16)};
`;

const LocaleContainer = styled("div")`
	display: inline-flex;
	width: ${pxToRem(50)};
	margin-left: var(--space-s);
`;

const Nav = styled("nav")`
	display: flex;
`;

const DesktopNav: React.FC = () => {
	const [openLanguageChange, LanguageChangeModal] = useConfirmLanguageChange();
	const { locale } = useLocale();

	const faqLinks = getConfig("faqlinks").general;
	const faqLink = faqLinks[locale];

	const hasMultipleLanguages = getConfig("locales").length > 1;

	return (
		<Nav>
			{LanguageChangeModal}
			<DesktopLink to={`/${loanRoutes.base}`} data-testid="desktop-loans-link">
				<FormattedMessage defaultMessage="Loans" />
			</DesktopLink>
			<DesktopLink to="/account" data-testid="desktop-account-link">
				<FormattedMessage defaultMessage="Account" />
			</DesktopLink>
			<DesktopLinkExternal
				href={faqLink}
				target="_blank"
				rel="noopener"
				data-testid="desktop-faqs-link"
			>
				<FormattedMessage defaultMessage="FAQs" />
			</DesktopLinkExternal>
			<DesktopLink to="/logout" data-testid="desktop-sign-out-link">
				<FormattedMessage defaultMessage="Sign Out" />
			</DesktopLink>
			{hasMultipleLanguages && (
				<>
					<Divider orientation="vertical" />
					<LocaleContainer>
						<LocaleDropdown
							selected={locale}
							selectLocale={(newLocale) => {
								openLanguageChange(newLocale);
							}}
						/>
					</LocaleContainer>
				</>
			)}
		</Nav>
	);
};

export { DesktopNav };
